import React from "react";
import DownArrow from "../../assets/images/downArrow.png";
import Size from "../../assets/images/Size.png";
import RightArrow from "../../assets/images/rightArrow.png";
import Money from "../../assets/images/Money.png";
import TwentyFirst from "../../assets/images/partner1.png";
import Agrostrong from "../../assets/images/agrostrong-dk.png";
import Tsigiro from "../../assets/images/tsig.png";
import Jamboo from "../../assets/images/jamboo.png";

const DiasporaOppotunity = () => {
  return (
    <div className="capital-structure">
      <div>
        <div
          className="text-overline"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          OPPORTUNITY!
        </div>
        <p
          className="title"
          style={{ color: "#000", textAlign: "center", fontSize: 25 }}
        >
          <strong>THE DIASPORA OPPORTUNITY</strong>
        </p>
      </div>
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <div className="invest-flex">
        <div className="centered">
          <section className="title-container">
            <p className="section-title">
              DIASPORA BOND
              <br />
              <ul style={{ textAlign: "left" }}>
                <li>Pension Funds</li>
                <li>Institutional Investors</li>
              </ul>
            </p>
          </section>
          <img src={DownArrow} style={{ height: 75, width: 25 }} />
          <section className="title-container">
            <p className="section-title">
              <ul style={{ textAlign: "left" }}>
                <li>5-10% of the Diaspora Mortgage Market</li>
                <li>100-200k Mortgages</li>
                <li>US$ 4-8 Billion (addressable book)</li>
              </ul>
            </p>
          </section>
          <img src={DownArrow} style={{ height: 75, width: 25 }} />
          <section className="title-container-flex">
            <section>
              <img src={Size} style={{ height: 70, width: 50 }} />
              <p style={{ fontSize: 10 }}>
                <span style={{ fontWeight: "700" }}>2-3 million</span>{" "}
                diasporian Zimbabwean
              </p>
            </section>
            <img
              src={RightArrow}
              style={{ height: 25, width: 75, marginBottom: 40 }}
            />
          </section>
        </div>
        <div className="right-arrow">
          <img
            src={RightArrow}
            style={{ height: 25, width: 75, marginBottom: 40 }}
          />
        </div>
        <div className="down-arrow">
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 40 }}
          />
        </div>
        <div className="centered">
          <div className="section-title">
            <p>ENABLERS</p>
            <img src={TwentyFirst} style={{ height: 50 }} />
            <img src={Agrostrong} style={{ height: 50 }} />
            <img src={Tsigiro} style={{ height: 50 }} />
          </div>
          <section style={{ marginTop: 20 }}>
            <img
              src={Money}
              style={{ height: 70, width: 70, alignSelf: "center" }}
            />
            <p style={{ fontSize: 10 }}>
              <span style={{ fontWeight: "700" }}>US$2 billion</span> Annual
              Remmitance FY 2022
            </p>
          </section>
        </div>
        <div className="right-arrow">
          <img
            src={RightArrow}
            style={{ height: 25, width: 75, marginBottom: 40 }}
          />
        </div>
        <div className="down-arrow">
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 40 }}
          />
        </div>
        <div className="centered">
          <section className="section-title">
            <p>Enablers:</p>
            <ul style={{ textAlign: "left" }}>
              <li>Land Banks</li>
              <li>Developments from other partners</li>
              <li>Own Development Pipeline</li>
              <li>Partnerships with Municipalities</li>
            </ul>
          </section>
          <img src={DownArrow} style={{ height: 75, width: 25 }} />
          <section className="section-title">
            <ol style={{ textAlign: "left" }}>
              <li>Asset Management</li>
              <ul style={{ textAlign: "left" }}>
                <li>Property Fund (5-10% of Remittances Value).</li>
                <li>Annual Inflows US$ 100-200 million.</li>
              </ul>
              <li>Funeral Cover & Life Cover:</li>
              <ul>
                <li>Diaspora Funeral Cover</li>
                <li>Life Cover</li>
              </ul>
              <li>Diaspora Banking:</li>
              <ul>
                <li>Mortgages (Stands, Building, Houses)</li>
              </ul>
            </ol>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DiasporaOppotunity;
