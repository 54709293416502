import React from "react";
import RightArrow from '../../assets/images/rightArrow.png';
import DownArrow from '../../assets/images/downArrow.png';

const ValueProposition = () => {
  return (
    <div className="capital-structure">
      <div>
        <div
          className="text-overline"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          THE VALUE PROPOSITION: TSIGIRO-USEKELO PROPERTY FUND
        </div>
        <p
          className="title"
          style={{ color: "#000", textAlign: "center", fontSize: 25 }}
        >
          <strong>A Diaspora Kapita Collective Investment Scheme</strong>
        </p>
      </div>
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        {/* #e3944a */}
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <div className="invest-flex">
        <div className="centered">
          <div className="yellow-container">
            <h5 style={{ fontSize: 13, textAlign: 'center', fontWeight: '600' }}>PRODUCTS & SERVICES</h5>
            <div className="grey-container">
              <p>Banking Services : </p>
              <p>Transactional Banking </p>
              <p style={{ fontSize: 10 }}>(Jambo and a partner Zimbabwean bank )</p>
            </div>
            <div className="grey-container">
              <p>Loans: </p>
              <p>Mortages </p>
              <p>Personal/Business Loans</p>
            </div>
            <div className="grey-container">
              <p>Insurance: </p>
              <p>Funeral Cover </p>
              <p>Life Cover</p>
              <p>Building Insurance</p>
            </div>
            <div className="grey-container">
              <p>Retirement Planning </p>
            </div>
            <div className="grey-container">
              <p>Diaspora Family Support Services</p>
              <p>Food</p>
              <p>Health Services</p>
            </div>
          </div>
        </div>
        <div className="right-arrow">
          <img
            src={RightArrow}
            style={{ height: 25, width: 75, marginBottom: 40 }}
          />
        </div>
        <div className="down-arrow">
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 40 }}
          />
        </div>
        <div className="centered">
          <div className="white-container">
            <p style={{ fontWeight: '600' }}>DIASPORA CLIENTS BUY PROPERTY</p>
            <ul style={{ textAlign: 'left' }}>
              <li>10% Deposit on Property Purchase</li>
              <li>15-Year Mortgage Term</li>
              <li>14% Average Mortgage Interest Rate</li>
            </ul>
          </div>
          <div className="grey-container" style={{ height: 'auto', fontSize: 13, width: 300, padding: 10 }}>
            <p style={{ fontWeight: '600' }}>PROPERTY DEVELOPMENT POOL </p>
            <ul style={{ textAlign: 'left', marginTop: 10 }}>
              <li>Municipalities</li>
              <li>Other property companies</li>
              <li>Own property developments</li>
            </ul>
          </div>
        </div>
        <div className="right-arrow">
          <img
            src={RightArrow}
            style={{ height: 25, width: 75, marginBottom: 40 }}
          />
        </div>
        <div className="down-arrow">
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 0 }}
          />
        </div>
        <div className="centered">
          <div className="grey-container" style={{ height: 'auto', fontSize: 13, width: 300, padding: 10, width: 300 }}>
            <p> 200,000 Diasporans @ US$ 10 per member
              [no restrictions for locals with free funds]</p>
          </div>
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 0 }}
          />
          <div className="yellow-container" style={{ fontSize: 13, textAlign: 'center', marginTop: 0, width: 300 }}>
            <p style={{ fontWeight: '600' }}>DIASPORA PROPERTY FUND</p>
            <ul style={{ textAlign: 'left' }}>
              <li>Provides security to Mortgage Providers.</li>
              <li>Co-Invest in property with property developers.</li>
              <li>Enables better mortgage conditions for members.</li>
            </ul>
          </div>
          <img
            src={DownArrow}
            style={{ height: 75, width: 25, marginBottom: 0 }}
          />
          <div className="colored-container-flex" >
            <div className="grey-container" style={{ width: 140 }}>
              <p>Assets Generating Perpetual Income</p>
            </div>
            <div className="grey-container" style={{ width: 140 }}>
              <p>Other Investments</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
